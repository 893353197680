import React from 'react'

export default function footer() {
  return (
    <div style={{width:"100%",gap:"20px",height:"30vh",backgroundColor:"#273c75",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column"}}>
    <div style={{display:"flex"}}>
    <a style={{color:"blue" , backgroundColor:"white" ,width:"40px",height:"40px", padding:"5px" , alignItems:"center",justifyContent:"center",display:"flex",borderRadius:"10px",margin:"0 10px",fontWeight:"600",textDecoration:"none",fontSize:"20px"}} href="https://www.facebook.com/3keel/?locale=ar_AR" class="fa fa-facebook"></a>
    <a style={{color:"red" , backgroundColor:"white" ,width:"40px",height:"40px", padding:"5px" , alignItems:"center",justifyContent:"center",display:"flex",borderRadius:"10px",margin:"0 10px",fontWeight:"600",textDecoration:"none",fontSize:"20px"}} href="https://www.instagram.com/3keell/?img_index=1#" class="fa fa-instagram"></a>
    </div>
    <h5 style={{color:"white"}}>جميع الحقوق محفوظة | شركة جوهرة العلوية</h5>
    </div>
  )
}
