import React, { useState } from "react";
import "./header.css"


function Header() {


    return (
      <div className="nav">
     
          <div className="logo">
          <h4>جوهرة العلوية</h4>
          <p>للتجارة العامة</p>
          </div>
          <div className="list-items">
            <ul className="list">
              <li>الرئيسية</li>
              <li>عن الشركة</li>
              <li>الخدمات</li>
              <li>تواصل معنا</li>
            </ul>
            
          </div>

      </div>
      
    );
  }
  
  export default Header;