import "./about.css"

function About(){
    return(
        <div className="about-section">
            <h1 className="about-title" style={{paddingTop:"50px",color:"black"}}>نبذة عن الشركة</h1>
            <h1 className="tag-name">جوهرة العلوية</h1>
               <div className="main-contentt">
                    <p>هي شركة رائدة في مجال بيع وتوزيع الإطارات والبطاريات والزيوت. تأسست الشركة في ( 1993) وقد نمت لتصبح واحدة من الشركات البارزة في بيع اطارات السيارات وخدمات الصيانة</p>
                    <h3>المنتجات والخدمات</h3>
                    <p>تقدم مجموعة واسعة من المنتجات والخدمات لتلبية احتياجات العملاء. تشمل هذه المنتجات</p>
                    <p>الإطارات: توفير مجموعة متنوعة من الإطارات للسيارات الركاب، الشاحنات، والمركبات الثقيلة. تتميز الإطارات بجودتها وأدائها العالي</p>
                    <p>البطاريات: توفير بطاريات عالية الجودة وفعالة من حيث الطاقة لمجموعة متنوعة من المركبات.</p>
                    <p>الزيوت : توفير مجموعة من الزيوت والسوائل المحركة للمحافظة على صحة وأداء المحركات.</p>
                </div>
        </div>
    )
}

export default About;