
import './App.css';
import Header from './component/Header'
import  Main  from './component/Main';
import Marks from './component/Marks';
import About from './component/about/About'
import Service from './component/service/Service';
import Contact from './component/contact/Contact';
import Footer from './component/footer/footer';


function App() {
  
  return (
    <div className="app">
    <Header/>
    <Main/>
    <Marks/>
    <About/>
    <Service/>
    <Contact/>
    <Footer/>
    </div>
  );
}

export default App;
