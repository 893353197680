import "./main.css"
import React, { useState ,useEffect} from 'react'
import Photos from "./Photos"
import Click from './Click';
import SideBar from './SideBar';


function Main(){
  const [open,setopen]=useState(false);
  
  const [photoCount,setphotoCount]=useState(0);
  console.log(open);
  useEffect(()=>{
    setInterval(()=>{
      setphotoCount((count)=>{return count===2?0:count+1})
    },3000)
  },[]);

  
  
  return (
    <div className="hero-main">
    <SideBar open={open} />
    <div>
    <div className="click-items" onClick={()=>setopen((prev)=>!prev)} >
    <div style={{width:"25px",height:"2px" , backgroundColor:"white"}}></div>
    <div style={{width:"15px",height:"2px" , backgroundColor:"white"}}></div>
    <div style={{width:"10px",height:"2px" , backgroundColor:"white"}}></div>
  </div>
    </div>
    <div className='hero' >
      <div className='right'>
          <h2>مرحبا بك في جوهرة العلوية</h2>
          <h5>اكتشف جودة الأداء المتميزة لسيارتك معنا</h5>
          <button className='btn'>تعرف على الخدمات</button>
          
      </div>
      <div className='left'>
          <div className='circle1'>
            <div className='circle2'>
            <Photos photoCount={photoCount}/>
            </div>
          </div>
          </div>
    </div>
    
    </div>
  )
}
export default Main;