import "./marks.css"
import double from "../component/assts/doublestar.jpg"
import longway from "../component/assts/LONGWAy.png"
import brg from "../component/assts/Bridgestone.png"
import ling from "../component/assts/LingLong.jpg"
import kapsen from "../component/assts/kapsen.png"
import solite from "../component/assts/solite.png"
import indigo from "../component/assts/indigo.jpg"


function Marks(){
    return(
        <div className="main-content">
            <div className="product">
                <img src={double}></img>
                <img src={longway}></img>
                <img src={brg}></img>
                <img src={ling}></img>
                <img src={kapsen} ></img>
                <img src={solite} ></img>
                <img src={indigo} ></img>
            </div>
            <div className="product">
                <img src={double}></img>
                <img src={longway}></img>
                <img src={brg}></img>
                <img src={ling}></img>
                <img src={kapsen} ></img>
                <img src={solite} ></img>
                <img src={indigo} ></img>
            </div>
            <hr></hr>
        </div>
    )
}

export default Marks;