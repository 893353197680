

export default function SideBar({open}) {
    if(open){
  return (
    <div>
    <ul  style={{position:"absolute",width:"100%",height:"100vh",color:"white",backgroundColor:"#273c75",gap:"20px",display:"flex",flexDirection:"column",listStyle:"none",justifyContent:"center",alignItems:"center",zIndex:"1000"}}>
        <li>الرئيسية</li>
        <li>عن الشركة</li>
        <li>الخدمات</li>
        <li>تواصل معنا</li>
  </ul>
    </div>
  ) }else{
    return (
        <div>
        <ul  style={{position:"absolute",width:"480px",height:"100vh",color:"white",backgroundColor:"#273c75",gap:"20px",display:"none",flexDirection:"column",listStyle:"none",justifyContent:"center",alignItems:"center",zIndex:"1000"}}>
        <li>الرئيسية</li>
        <li>عن الشركة</li>
        <li>الخدمات</li>
        <li>تواصل معنا</li>
      </ul>
        </div>
      )
  }
}
