import "./service.css"
import tirepic from "../assts/tire.png"
import batterypic from "../assts/battery.jpg"
import oilpic from "../assts/oil.png"
import {motion} from "framer-motion"

function Service(){

    const variants={
        initial:{
            opacity:0,
            x:-80
        },
        animate:{
            opacity:1,
            x:0,
            transition:{duration:0.8,staggerChildren:0.8}
        }
    }
    const variants2={
        initial:{
            opacity:0,
            x:100
        },
        animate:{
            opacity:1,
            x:0,
            transition:{duration:1,staggerChildren:1}
        }
    }

    return(
        <div className="service-main" >
            <h1 style={{display:"flex", alignItems:"center",justifyContent:"center" ,margin:"10px 0 100px 0",fontWeight:"600"}}>الخدمات</h1>
            
           
                <div className="tire" >

                  <motion.div style={{display:"flex" , alignItems:"center",justifyContent:"center"}} 
                  initial="initial" whileInView="animate" variants={variants} className="tire-pic">

                    <motion.img className="tire-section-pic" variants={variants} src={tirepic} style={{}} ></motion.img>

                    <motion.div className="service-text" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center" ,padding:"0 20px"}} initial="initial" whileInView="animate" variants={variants2} >
                    <motion.h1 variants={variants2} style={{margin:"30px 0",fontSize:"40px"}}>الاطارات</motion.h1>
                    <motion.p variants={variants2} style={{width:"100%",direction:"rtl"}}>الإطارات: توفير مجموعة متنوعة من الإطارات للسيارات الركاب، الشاحنات، والمركبات الثقيلة تتميز الإطارات بجودتها وأدائها العالي</motion.p>
                    </motion.div>
                  </motion.div>
                </div>

                <div className="battery" style={{marginTop:"100px"}} >

                  <motion.div style={{display:"flex" , alignItems:"center",justifyContent:"center"}} 
                  initial="initial" whileInView="animate" variants={variants} className="tire-pic">

                    <motion.img className="tire-section-pic" variants={variants} src={batterypic} ></motion.img>

                    <motion.div className="service-text" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} initial="initial" whileInView="animate" variants={variants2} >
                    <motion.h1 variants={variants2} style={{margin:"30px 0",fontSize:"40px"}}>البطاريات</motion.h1>
                    <motion.p variants={variants2} style={{width:"100%",direction:"rtl"}}>توفر جوهرة العلوية مجموعة واسعة من بطاريات السيارات بمختلف انواعها واحجامها وبأسعار مناسبة جدا </motion.p>
                    </motion.div>
                  </motion.div>
                </div>
           


            <div className="oil" style={{marginTop:"100px"}} >

                  <motion.div style={{display:"flex" , alignItems:"center",justifyContent:"center"}} 
                  initial="initial" whileInView="animate" variants={variants} className="tire-pic">

                    <motion.img className="tire-section-pic" variants={variants} src={oilpic} ></motion.img>

                    <motion.div className="service-text" style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} initial="initial" whileInView="animate" variants={variants2} >
                    <motion.h1 variants={variants2} style={{margin:"30px 0",fontSize:"40px"}}>الزيوت</motion.h1>
                    <motion.p variants={variants2} style={{width:"100%",direction:"rtl"}}>توفير مجموعة من الزيوت والسوائل المحركة للمحافظة على صحة وأداء المحركات</motion.p>
                    </motion.div>
                  </motion.div>
                </div>
            </div>
        
    )
}

export default Service;