import img1 from "../component/assts/battery.jpg"
import img2 from "../component/assts/tire.png"
import img3 from "../component/assts/oil.png"
import "./photo.css"

 const Photos = ({photoCount}) => {
    if(photoCount===0){
        return <img src={img1} className="circle-pic slide"></img>
    }else if(photoCount===1){
        return <img src={img2} className="circle-pic slide"></img>
    }else if(photoCount===2){
        return <img src={img3} className="circle-pic slide"></img>
    }
}
export default Photos;