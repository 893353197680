import React from 'react'
import jawharat from "../contact/jawharat al alwaya log.png"
import {motion} from "framer-motion"
import "./contact.css"

export default function Contact() {

    const variants={
        initial:{
            opacity:0,
            y:100
        },
        animate:{
            opacity:1,
            y:0,
            transition:{duration:1,staggerChildren:0.2}
        }
    }
    const variants2={
        initial:{
            opacity:0,
            x:100
        },
        animate:{
            opacity:1,
            x:0,
            transition:{duration:1,staggerChildren:1}
        }
    }

  return (

    

    <div className='contact-main' 
    style={{width:"100%",height:"100vh",flexDirection:"column", display:"flex",color:"white",backgroundColor:"#2c3e50",justifyContent:"center",alignItems:"center"}}>

   
    <div className='contact-second' style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
        <motion.div initial="initial" whileInView="animate" variants={variants} className='right'>
        <motion.h2 className='h2-contact' variants={variants} style={{display:"inline-flex" ,paddingBottom:"1px"}}>تواصل معنا</motion.h2>
            <motion.div className='text-div' variants={variants} style={{display:"flex",justifyContent:"center",alignItems:"center" , flexDirection:"column" , gap:"10px"}}>
                <motion.h5 variants={variants}>اوقات الدوام</motion.h5>
                <motion.h5 variants={variants}> طيلة ايام الأسبوع 24/7</motion.h5>
                <motion.div variants={variants} style={{width:"50%",height:"2px", backgroundColor:"red"}}></motion.div>
                <motion.h6 variants={variants}>الهاتف: 009647811642388</motion.h6>
                <motion.h6 variants={variants}>موقع الشركة: النجف الأشرف - شارع كراج بغداد - قرب مطعم سنونو</motion.h6>
                <motion.h6 variants={variants} style={{direction:"rtl"}}>ايميل: jawharat.alwiya@gmail.com</motion.h6>
            </motion.div>
        </motion.div>
        <motion.div className='left' initial="initial" whileInView="animate" variants={variants2}>
            <div className='photo-div'><img className='contact-pic' src={jawharat}></img></div>
        </motion.div>
    </div>
    
      
    </div>
  )
}
